import React, { useState, useEffect } from "react";
import processimage1 from "./images/process1.jpg";
import processimage2 from "./images/process2.jpg";
import processimage4 from "./images/process4.jpg";
import back from "./images/arrow_back_ios_16dp_WHITE_FILL0_wght400_GRAD0_opsz20.png";
import next from "./images/arrow_forward_ios_16dp_WHITE_FILL0_wght400_GRAD0_opsz20.png";

function Process() {
    const images = [
        { id: 1, src: processimage1, alt: "Image 1", text: "1. ESCUCHAMOS TU AMBICIÓN." },
        { id: 2, src: processimage2, alt: "Image 2", text: "2. TE PLANTEAMOS LA SOLUCIÓN" },
        { id: 3, src: processimage4, alt: "Image 3", text: "3. LA SUBIMOS EN LÍNEA" }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    // Función para avanzar a la siguiente imagen
    const goToNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    // Función para retroceder a la imagen anterior
    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    // Efecto para avanzar automáticamente las imágenes cada 3 segundos
    useEffect(() => {
        const interval = setInterval(goToNext, 3000); // Cambia cada 3 segundos
        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
    }, [currentIndex]);

    return (
        <div className="process reveal" id="process">
            <div className="process__container">
                <div className="process__overlay"></div> {/* Capa oscura */}
                <div className="process__content">
                    <div className="process__back-container">
                        <img src={back} alt="Atrás" className="process__back" onClick={goToPrevious} />
                    </div>
                    <div className="process__pictures-container">
                        <div className="process__title-container">
                            <h4 className="process__title">NUESTRO PROCESO</h4>
                        </div>
                        <img 
                            src={images[currentIndex].src} 
                            alt={images[currentIndex].alt} 
                            className="process__image" 
                        />
                        <div className="process__picture-container-text">
                            <p className="process__picture-text">{images[currentIndex].text}</p>
                        </div>
                    </div>
                    <div className="process__next-container">
                        <img src={next} alt="Adelante" className="process__next" onClick={goToNext} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Process;
