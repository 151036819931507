import React, { useState, useEffect } from "react";
import videoBanner from "./images/horizontal3.mp4";

import videoBannerVerticalGif from "./images/videoplayback_1.gif";

function Banner() {
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    // Detectar si el dispositivo es iOS
    setIsVertical(window.innerWidth < 867);
  }, []);

  return (
    <div className="banner" id="banner">
      {isVertical ? (
        <div className="banner__video">
          <img
            src={videoBannerVerticalGif}
            alt="Vertical Gif"
            className="banner__video-gif"
          />
        </div>
      ) : (
        <video className="banner__video" muted autoPlay loop>
          <source
            src={videoBanner}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="banner__title-container">
        <h1 className="banner__title">DANTE'S CLOSET</h1>
        <p className="banner__subtitle">JUEGA CON TU AMBICIÓN</p>
      </div>
    </div>
  );
}

export default Banner;
