import React from "react";
import offerpicture1 from "./images/1.jpg";
import offerpicture2 from "./images/Shutterstock_796662088.jpg";
import offerpicture3 from "./images/3.jpg";

function Offer() {
  return (
    <div className="offer reveal" id="offer">
      <h1 className="offer__title">DANTE´S CLOSET OFERTA COMERCIAL</h1> {/* Corrección ortográfica */}

      <div className="offer__container">
        <img src={offerpicture1} alt="" className="offer__picture" />
        <div className="offer__text-container">
          <h4 className="offer__subtitle">TRANSFORMAMOS TU AMBICIÓN EN ÉXITO</h4> {/* Corrección ortográfica */}
          <p className="offer__text">
          Creamos landing pages estratégicas para maximizar tu presencia en línea y generar resultados medibles. Nuestro equipo entiende tus objetivos y diseña soluciones personalizadas que impulsan tu crecimiento.
          </p>
        </div>
      </div>

      <div className="offer__container">
        <div className="offer__text-container">
          <p className="offer__text">
          Desde start-ups hasta negocios consolidados, estamos aquí para llevar tu visión al siguiente nivel con creatividad, datos e innovación. Sea cual sea tu pasión, tenemos las herramientas para ayudarte a destacar en un mercado competitivo.</p>
        </div>
        <img src={offerpicture2} alt="" className="offer__picture" />
      </div>
    </div>
  );
}

export default Offer;
