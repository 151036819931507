import work1 from './images/work4.jpg';
import work2 from './images/work5.jpg';
import work3 from './images/work6.jpg';

function Work() {
  const works = [
    { img: work1, title: "NOVA FRONT", url: 'https://skrollex.romanmeleshin.art/theme-suzi/', description: "Estilo futurista y minimalista, ideal para startups innovadoras."},
    { img: work2, title: "ECLIPSE VIBE", url: 'https://creazione.avanzare.co/legendary/demo/index-2.html?bg=space', description: "Diseño moderno con temática espacial, perfecto para proyectos tecnológicos."},
    { img: work3, title: "TITANIUM GRID", url: 'https://javier1511.github.io/', description: "Minimalista y organizado, pensado para startups enfocadas en funcionalidad."
 }
  ];

  return (
    <div className="work reveal" id='work'>
      <div className="work__main-container">
        <div className="work__title-container">
          <p className="work__title">NUESTRO MODELOS</p>
        </div>
        <div className="works__container">
          {works.map((work, index) => (
            <div className="work__cards-container" key={index}>
              <div className="work__cards">
                <img src={work.img} alt="" className="work__cards-img" />
                <span className="work__cards-hover-text">
                  <span className="text-content">{work.description}</span>
                </span>
              </div>

              <a href={work.url} className="work__cards-subtitle">
                <p className="work__subtitle">{work.title}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Work;
