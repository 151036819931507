import Banner from './Banner';
import Header from './Header';
import Offer from './Offer';
import Process from './Process';
import Work from './Work';
import Contact from './Contact';
import Footer from './Footer';
import WhatsApp from './WhatsApp';
import './App.css';
import { useEffect, useState } from 'react';
import { reveal } from './reveal.js';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() =>{
    setTimeout(() =>{
      setLoading(false); // Cambia el estado a false cuando termina el tiempo de carga simulado
    }, 5000);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', reveal)

    reveal();

    return() =>{
      window.removeEventListener('scroll', reveal)
    };
  },[]);

  return (
    <div>
      { loading ? (
        <div className='loader'>
        </div>
      ) : (
        <div className="App">
          <Banner />
          <Header />
          <Offer />
          <Process />
          <Work />
          <Contact />
          <Footer />
          <WhatsApp></WhatsApp>
        </div>
      )}
    </div>
  );
}

export default App;
